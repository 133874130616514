import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px',
    borderRadius: 8,
  },
  icon: {
    marginRight: 16,
  },
  title: {
    fontWeight: 'normal',
    fontSize: '1rem',
  },
  value: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  textField: {
    width: '80px',
    height: '30px',

    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-input': {
        padding: '8px 8px',
        textAlign: 'center',
        fontSize: '1rem',
        fontWeight: 'normal',
      },
    },
  },
  textFieldContainer: {
    marginLeft: 'auto',
  },
  text: {
    marginRight: '30%',
  },
  content: {},
});
