import { Box, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ACCUEIL_URL } from "../../../constant/url";
import { useStyles } from "./style";
import logoC4W from "../../../assets/logo/logo_chain4test.svg";
import { AuthContext } from "../../../provider/Authprovider";

const HeaderTransfert = () => {
  const classes = useStyles();
  const history = useHistory();
  const { logout } = useContext(AuthContext);

  const goToHome = () => {
    history.push(ACCUEIL_URL);
  };

  useEffect(() => {
    if (localStorage.getItem("isFirstTab") === "true") {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.transfertHeader}>
      <Box className={classes.logo}>
        <div className={classes.logoC4W}>
          <img src={logoC4W} alt="C4W" />
        </div>
      </Box>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Button
          variant="text"
          color="default"
          startIcon={<CloseIcon />}
          onClick={goToHome}
        >
          Fermer
        </Button>
      </Box>
    </Box>
  );
};

export default HeaderTransfert;
