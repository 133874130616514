import DateFnsUtils from "@date-io/date-fns";
import { Box, Typography } from "@material-ui/core";
import moment from "moment";
import localization from "date-fns/locale/fr";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { FC } from "react";
import { useStyles } from "./style";
import { INVALID_DATE_MAX, INVALID_FORMAT_DATE } from "../../constant";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";

moment.locale("fr-Fr");

interface CustomDateProps {
  value: any;
  name: string;
  views?: Array<"year" | "date" | "month">;
  format?: string | undefined;
  autoOk?: boolean;
  handleDateChange: (date: any, value?: string | null | undefined) => void;
  disableFuture?: boolean;
  onOpen?: () => void;
  allowKeyboardControl?: boolean;
  open?: boolean;
  readOnly?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClose?: () => void;
  minDate?: ParsableDate;
  maxDate?: ParsableDate;
}

export const CustomDatePickers: FC<CustomDateProps> = ({
  handleDateChange,
  value,
  format,
  views = ["date"],
  name,
  autoOk = true,
  disableFuture,
  onOpen,
  allowKeyboardControl,
  open,
  readOnly,
  onClick,
  onClose,
  minDate,
  maxDate,
}) => {
  const style = useStyles();

  return (
    <Box className={style.selectDateItem}>
      <Typography variant="h6" className={style.labeled_select}>
        {name}
      </Typography>
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        libInstance={moment}
        locale={localization}
      >
        <KeyboardDatePicker
          allowKeyboardControl={allowKeyboardControl}
          open={open}
          readOnly={readOnly}
          onOpen={onOpen}
          className={style.formControl}
          variant="inline"
          views={views}
          inputVariant="outlined"
          margin="none"
          minDateMessage="Intervalle de dates invalide"
          maxDateMessage={INVALID_DATE_MAX}
          invalidDateMessage={INVALID_FORMAT_DATE}
          id="date-picker-dialog"
          format={format}
          value={value}
          onChange={handleDateChange}
          orientation="portrait"
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          autoOk={autoOk}
          disableFuture={disableFuture}
          onClick={onClick}
          onClose={onClose}
          onKeyDown={() => {
            return false;
          }}
          InputProps={{
            disabled: true,
          }}
          minDate={minDate}
          maxDate={maxDate}
        />
      </MuiPickersUtilsProvider>
    </Box>
  );
};
