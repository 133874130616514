import gql from "graphql-tag";

export const OnTotalUpdated = gql`
  subscription onTotalUpdate {
    onTotalUpdate {
        users
        euCoins
        affinity
    }
  }
`;
