import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  TextField,
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core';
import { CustomButton } from '../../../../../common/CustomButton/CustomButton';
import { useHistory } from 'react-router-dom';
import {
  ACCUEIL_URL,
  COMMISSIONING_URL,
  LOGIN_URL,
  SIGNUP_URL_ID,
} from '../../../../../constant/url';
import { useStyles } from '../../style';
import { useMutation } from '@apollo/client';
import { DO_SIGNUP } from '../../../../../graphql/SignUp/mutation';
import {
  signUp,
  signUpVariables,
} from '../../../../../graphql/SignUp/__generated__/signUp';
import { CustomSnackBar } from '../../../../../common/CustomSnackBar/CustomSnackBar';
import { CustomBackdrop } from '../../../../../common/BackDrop/BackDrop';
import { TOKEN } from '../../../../../constant';
import { getUser } from '../../../../../provider/localesStorage';
import { AuthContext } from '../../../../../provider/Authprovider';

interface ISignupPersonPhysique {
  lastName: string;
  firstName: string;
  id: string;
  password: string;
  isLegalEntity: boolean;
}

const CardSignUpPP: React.FC = () => {
  const { setToken, setUserInfos } = useContext(AuthContext);
  const user = getUser();
  const style = useStyles();
  const [input, setInput] = useState<ISignupPersonPhysique>({
    lastName: '',
    firstName: '',
    id: '',
    password: '',
    isLegalEntity: true,
  });
  const history = useHistory();
  const [idError, setIdError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [activ, setactiv] = useState<boolean>(false);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#B48A4E',
      },
    },
  });

  const [doSignUp, { loading: doSignUpLoading }] = useMutation<
    signUp,
    signUpVariables
  >(DO_SIGNUP, {
    onCompleted: (data) => {
      if (data.signUp.data) {
        history.push(SIGNUP_URL_ID, {
          identifiant: input.id,
          password: input.password,
        });
      } else {
        setOpen(true);
        setType('error');
        setMessage(data.signUp.message || 'Une erreur est survenue');
      }
    },
    onError: (errors) => {
      setOpen(true);
      setType('error');
      if (input.firstName === '' || input.lastName === '') {
        setMessage('Veuillez completer tous les champs!');
      } else {
        errors?.graphQLErrors?.map((error) => {
          setMessage(error.message);
          return error;
        });
      }
    },
  });

  const onKeyPressed = (e: any) => {
    if (e.key === 'Enter') {
      if (
        input.lastName !== '' &&
        input.firstName !== '' &&
        input.id !== '' &&
        input.password !== ''
      ) {
        handleSubmit();
      } else {
        setOpen(true);
        setType('error');
        setMessage('Veuillez completer tous les champs!');
      }
    }
  };

  useEffect(() => {
    if (
      !/^[a-zA-Z\u00C0-\u00FF0-9 ]*$/.test(
        `${input.lastName} ${input.firstName}`,
      ) &&
      (input.lastName !== '' || input.firstName !== '')
    ) {
      setactiv(false);
      setOpen(true);
      setType('error');
      setMessage('Les caractères spéciaux ne sont pas autorisés');
    } else if (
      input.lastName !== '' &&
      input.firstName !== '' &&
      input.password !== '' &&
      input.id !== ''
    ) {
      setactiv(true);
    } else {
      setactiv(false);
    }
  }, [input]);

  useEffect(() => {
    const token = localStorage.getItem(TOKEN);
    if (!!token && user) {
      setToken(token);
      setUserInfos({
        isAdmin: user.isAdmin,
        userName: user.userName,
      })
      if (user.isAdmin) {
        history.push(COMMISSIONING_URL);
      } else {
        history.push(ACCUEIL_URL);
      }
    }
  }, []);

  const handleChange = (event: any) => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === 'lastName' || name === 'firstName' || name === 'id') {
      value = value.trim();
    }

    setInput((prevstate) => ({ ...prevstate, [name]: value }));
  };

  const handleSubmit = () => {
    const { firstName, lastName, id, password } = input;
    if (id.length < 6 || id.length > 20) {
      setOpen(true);
      setType('error');
      setMessage(
        'L’identifiant de connexion choisi est incorrect : 6 à 20 caractères sont obligatoires. Recommencez s’il vous plaît.',
      );
    } else if (isNaN(+password) || password.length !== 6) {
      setOpen(true);
      setType('error');
      setMessage(
        'Le mot de passe choisi est incorrect : 6 chiffres sont obligatoires. Recommencez s’il vous plaît.',
      );
    } else {
      doSignUp({
        variables: {
          signUpInput: {
            id: id.trim(),
            password: password,
            firstName: firstName.trim(),
            lastName: lastName.trim(),
          },
        },
      });
    }
  };

  return (
    <Box className={style.cardPP}>
      <Box>
        <TextField
          className={style.textField}
          type="text"
          name="lastName"
          label="Votre nom"
          variant="outlined"
          onChange={handleChange}
          onKeyDown={onKeyPressed}
        />
        <TextField
          type="text"
          name="firstName"
          variant="outlined"
          label="Votre prénom"
          className={style.textField}
          onChange={handleChange}
          onKeyDown={onKeyPressed}
        />
        <TextField
          className={style.textField}
          type="text"
          name="id"
          label="Votre identifiant de connexion"
          variant="outlined"
          inputProps={{ maxLength: 20 }}
          onChange={handleChange}
          onKeyDown={onKeyPressed}
          onFocus={() => setIdError(true)}
          onBlur={() => setIdError(false)}
          error={idError && (input.id.length < 6 || input.id.length > 20)}
          helperText={
            idError && (input.id.length < 6 || input.id.length > 20)
              ? '6 à 20 caractères au choix (facile à mémoriser par vous)'
              : ''
          }
        />
        <TextField
          className={style.textField}
          type="password"
          name="password"
          label="Votre mot de passe"
          variant="outlined"
          value={input.password}
          onChange={handleChange}
          onKeyDown={onKeyPressed}
          onFocus={() => setPasswordError(true)}
          onBlur={() => {
            setPasswordError(
              isNaN(+input.password) || input.password.length !== 6,
            );
          }}
          error={
            passwordError &&
            (isNaN(+input.password) || input.password.length !== 6)
          }
          helperText={
            passwordError &&
            (isNaN(+input.password) || input.password.length !== 6)
              ? '6 chiffres au choix (facile à mémoriser par vous)'
              : ''
          }
        />

        <ThemeProvider theme={theme}>
          <CustomButton
            disabled={!activ}
            color="primary"
            nameBtn="VALIDER"
            variant="contained"
            onClick={handleSubmit}
            className={style.btnconnect}
          />
        </ThemeProvider>

        <CustomButton
          color="primary"
          nameBtn="ABANDONNER"
          variant="outlined"
          onClick={() => history.push(LOGIN_URL)}
          className={style.btnconnect}
          style={{
            marginTop: 30,
            marginBottom: 20,
            backgroundColor: '#FFEBA2',
            borderColor: '#FFEBA2',
            color: '#B48A4E',
          }}
        />
      </Box>
      <CustomSnackBar
        open={open}
        setOpen={setOpen}
        message={message}
        type={type}
      />
      <CustomBackdrop open={doSignUpLoading} />
    </Box>
  );
};

export default CardSignUpPP;
