import moment from "moment";
import { COUNTRY_CODE } from "../constant";

/**
 * Founction for format start date
 *
 * @param view {number} 1 = view by years, 2 = view by months, 3 = view by days
 * @param date {Date}
 */
export const formatStartDateByView = (view: number, date: Date) => {
  switch (view) {
    case 1:
      return moment(date).startOf("year").toISOString();
    case 2:
      return moment(date).startOf("month").toISOString();
    case 3:
      return moment(date).startOf("day").toISOString();
    default:
      return moment(date).endOf("day").toISOString();
  }
};

/**
 * Founction for format end date
 *
 * @param view {number} 1 = view by years, 2 = view by months, 3 = view by days
 * @param date {Date}
 */
export const formatEndDateByView = (view: number, date: Date) => {
  switch (view) {
    case 1:
      return moment(date).endOf("year").toISOString();
    case 2:
      return moment(date).endOf("month").toISOString();
    case 3:
      return moment(date).endOf("day").toISOString();
    default:
      return moment(date).endOf("day").toISOString();
  }
};

// Function for format username with country code
export const formatUsernameWithCountryCode = (username: string) =>
  username.length === 0 ? "" : `${username} ${COUNTRY_CODE}`;

// Function for remove country code on username
export const removeCountryCodeOnUsername = (usernameWithCode: string) =>
  usernameWithCode.replace(COUNTRY_CODE, "").trim();

export const capitalizeFirstLetter = (str: string) => {
  return (
    str
      ?.split(" ")
      .map(
        (letter) =>
          letter.charAt(0).toUpperCase() + letter.slice(1).toLowerCase()
      )
      .join(" ") ?? ""
  );
};

export const formatAmountLetter = (amount: string) => {
  const match = amount.match(/(\d{1,3}(?:\s\d{3})*),(\d)$/);
  return match ? `${match[1]},${match[2]}0` : amount;
};

export const removeAccents = (str: string) =>
  str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const hexToRgb = (hex: string) => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return { r, g, b };
}

export const adjustBrightness = ({ r, g, b } : {r: number; g: number; b: number}, factor: number) => {
  return {
    r: Math.min(255, Math.max(0, Math.floor(r * factor))),
    g: Math.min(255, Math.max(0, Math.floor(g * factor))),
    b: Math.min(255, Math.max(0, Math.floor(b * factor))),
  };
}

export const rgbToHex = ({ r, g, b }: {r: number; g: number; b: number}) => {
  const toHex = (val: number) => val.toString(16).padStart(2, '0');
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}
