import Box from '@material-ui/core/Box';
import { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import Header from '../Header';
//@ts-ignore
import faqMd from './faq.md';
import { useStyles } from './style';

const FAQ = () => {
  const style = useStyles();
  const [faq, setFaq] = useState<string>('');

  useEffect(() => {
    fetch(faqMd)
      .then((response) => response.text())
      .then((text) => {
        setFaq(text);
      });
  }, []);

  return (
    <Box className={style.rootContainer}>
      <Header
        {...{ titleheader: 'Foire aux questions', subtitle: '', texte: '' }}
      />
      <Box className={style.boxMarkdown}>
        <Markdown children={faq} />
      </Box>
    </Box>
  );
};

export default FAQ;
