import {
  FormControl,
  InputBaseComponentProps,
  OutlinedInput,
} from "@material-ui/core";
import React, { FC } from "react";

interface CustomProps {
  variant?: "outlined" | "standard" | "filled" | undefined;
  id?: string;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  inputProps?: InputBaseComponentProps | undefined;
  labelWidth?: number | 0;
  onChange?:
    | ((
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => void)
    | undefined;
  className?: string;
  name?: string | undefined;
  value?: unknown;
  placeholder?: string | undefined;
  type?: string;
  required?: boolean;
  onKeyDown?: (
    event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  inputMode?:
    | "text"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search";
}

export const CustomTextField: FC<CustomProps> = ({
  variant,
  endAdornment,
  id,
  inputProps,
  labelWidth,
  onChange,
  startAdornment,
  className,
  name,
  value,
  placeholder,
  type,
  required,
  onKeyDown,
  inputMode,
}) => {
  return (
    <FormControl variant={variant}>
      <OutlinedInput
        inputMode={inputMode}
        onKeyDown={onKeyDown}
        required={required}
        id={id}
        endAdornment={endAdornment}
        startAdornment={startAdornment}
        inputProps={inputProps}
        labelWidth={labelWidth}
        onChange={onChange}
        name={name}
        value={value}
        placeholder={placeholder}
        type={type}
        className={className}
      />
    </FormControl>
  );
};
