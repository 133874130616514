import gql from 'graphql-tag';
import { TRANSACTION_HISTORY_FRAGMENT_INFOS } from '../TransactionHistory/fragment';

export const VALIDATE_COIN = gql`
  mutation validateCoin($validateCoinInput: validateCoinInput) {
    validateCoin(validateCoinInput: $validateCoinInput) {
      ...transactionHistoryFragmentInfos
    }
  }
  ${TRANSACTION_HISTORY_FRAGMENT_INFOS}
`;
