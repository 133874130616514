import React, { useState } from 'react';
import { Box, createMuiTheme, Typography } from '@material-ui/core';
import { useStyles } from './style';
import CreationPPCreation from './creationPPCreation/CreationPPCreation';
import CreationPPValidation from './creationPPValidation/CreationPPValidation';
import { setting } from '../../../../graphql/Setting/__generated__/setting';

const CreationPP: React.FC<{data?: setting}> = ({data}) => {
  const [choice, setChoice] = useState<boolean>(true);
  const style = useStyles();
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#B48A4E',
      },
    },
  });

  return (
    <Box className={style.transferContainer}>
      <Box className={style.containtWrappe}>
        <Box className={style.btnContainer}>
          <input
            type="checkbox"
            id="toggle"
            className={style.toggleCheckbox}
            checked={!choice}
            onChange={() => setChoice(!choice)}
          />
          <label htmlFor="toggle" className={style.toggleContainer}>
            <div className={style.toggleContainerDiv}>
              <Typography>CREATION</Typography>
            </div>
            <div className={style.toggleContainerDiv}>
              <Typography>VALIDATION</Typography>
            </div>
          </label>
        </Box>
        {choice ? <CreationPPCreation data={data} /> : <CreationPPValidation data={data}/>}
      </Box>
    </Box>
  );
};

export default CreationPP;
