import gql from 'graphql-tag';

export const DO_GET_ME = gql`
  query Me {
    me {
      id
      name
      lastName
      userName
      email
      address
      role
      isAdmin
      legalEntityId
      legalEntity {
        id
        denomination
        legalEntityTypeId
        legalEntityType {
          id
          label
        }
      }
    }
  }
`;
