import gql from 'graphql-tag';
import { TRANSACTION_HISTORY_FRAGMENT_INFOS } from '../TransactionHistory/fragment';

export const CONVERSION_TO_AFFINITY = gql`
  mutation ConversionToAffinity($conversionCoinInput: conversionCoinInput) {
    conversionToAffinity(conversionCoinInput: $conversionCoinInput) {
      transactionHistories {  
        ...transactionHistoryFragmentInfos
      }
    }
  }
  ${TRANSACTION_HISTORY_FRAGMENT_INFOS}
`;

export const CONVERSION_TO_EU_COIN = gql`
  mutation ConversionToEUCoin($conversionCoinInput: conversionCoinInput) {
    conversionToEUCoin(conversionCoinInput: $conversionCoinInput) {
      transactionHistories {  
        ...transactionHistoryFragmentInfos
      }
    }
  }
  ${TRANSACTION_HISTORY_FRAGMENT_INFOS}
`;
