import React from 'react';
import { useStyles } from './style';
import Header from '../acceuil/Header';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { TEXT_CONVERSION } from '../../../constant';
import EUAffinitaire from './methodes/EuAffinitaire';
import EUCoin from './methodes/EUCoin';
const Conversion: React.FC = () => {
  const style = useStyles();
  return (
    <Box className={style.rootContainer}>
      <Header
        {...{
          titleheader: 'Conversion',
          subtitle: TEXT_CONVERSION,
          texte: '',
        }}
      />
      <Box className={style.cardContainer}>
        <Card className={style.card}>
          <CardContent className={style.cardContent}>
            <EUAffinitaire />
          </CardContent>
        </Card>
        <Card className={style.card}>
          <CardContent className={style.cardContent}>
            <Typography className={style.typography}>
              <EUCoin />
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};
export default Conversion;
