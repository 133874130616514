import { createStyles, makeStyles, Theme } from "@material-ui/core";
//import { isWhiteSpaceLike } from "typescript";

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    rootContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      minHeight: "100%",
      margin: 0,
      padding: "22px 28px 0",
      width: "100%",
      "@media (max-width: 1023px)": {
        height: "100vh",
        padding: "12px 14px 0",
      },
      "@media (max-height: 767px)": {
        //overflowY: "auto",
      },
      "& .mainHistoryContainer": {
        marginTop: 190,
      },
      "& .mainContentBoxWrapper": {
        "&.scrollBarWrap": {
          height: "calc(100vh - 250px)",
        },
        "& > .simplebar-track.simplebar-horizontal": {
          display: "none",
        },
        "@media (min-height: 800px)": {
          "& > .simplebar-track": {
            "&.simplebar-vertical": {
              display: "none",
            },
          },
          "& > .simplebar-placeholder": {
            height: "auto !important",
            display: "none",
          },
          "& > .simplebar-mask, .simplebar-offset": {
            "@media (max-height: 699px)": {
              position: "static",
            },
          },
        },
        "&.ps": {
          "& .ps__rail-x": {
            display: "none",
          },
          "& .ps__rail-y": {
            "@media (min-height: 700px)": {
              height: "calc(100vh - 220px)",
            },
          },
        },
      },
    },
    errorTxt: {
      color: "#212226",
      fontFamily: "Poppins, sans-serif",
      fontSize: 30,
      fontWeight: 700,
      textAlign: "center",
      margin: "30px auto",
    },
    formControl: {
      height: 42,
    },
    selectEmpty: {
      //marginTop: theme.spacing(2),
    },
    selectValeur: {
      width: 235,
      "@media (max-width: 1199px)": {
        width: "100%",
      },
    },
    select: {},
    selectTransaction: {
      width: 298,
      "@media (max-width: 1199px)": {
        width: "100%",
      },
    },
    selectPeriode: {
      width: 166,
      "@media (max-width: 1199px)": {
        width: "100%",
      },
    },
    autoComplete: {
      width: 350,
      fontFamily: "Poppins, sans-serif",
      fontSize: "12px !important",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0.4px",
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
        padding: "5px",
      },
      "& input::placeholder": {
        fontFamily: "Poppins, sans-serif",
        fontSize: "12px !important",
        fontWeight: 400,
        lineHeight: "16px",
        letterSpacing: "0.4px",
      },
      "& input": {
        fontFamily: "Poppins, sans-serif",
        fontSize: "12px !important",
        fontWeight: 400,
        lineHeight: "16px",
        letterSpacing: "0.4px",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        background: "none",
        borderColor: "#B48A4E",
      },
      "@media (max-width: 1199px)": {
        marginLeft: "10px",
      },
    },
    autoCompleteLabel: {
      color: "#000000DE",
      fontFamily: "Poppins, sans-serif !important",
      fontSize: "12px",
      fontWeight: 400,
      marginBottom: "-15px",
    },
    optionStyle: {
      fontFamily: "Poppins, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: 0.25,
    },
    mainContentBox: {
      flex: "1 1 100%",
      width: "100%",
      "@media (max-height: 799px)": {
        maxHeight: "100%",
        scrollbarWidth: "none",
        //overflowX: "hidden",
      },
      "@media (min-height: 700px)": {
        overflow: "hidden",
      },
      "& .historyWrapper": {
        maxHeight: "100%",
        "@media (min-height: 700px)": {
          //height: "100%",
          maxHeight: "calc(100vh - 420px)",
          "@media (min-width: 1616px)": {
            maxHeight: "calc(100vh - 350px)",
          },
        },
        "@media (min-height: 800px)": {
          maxHeight: "calc(100vh - 450px)",
          //maxHeight: "calc(100vh - 385px)",
          "@media (min-width: 1616px)": {
            maxHeight: "calc(100vh - 370px)",
          },
        },
        "@media (min-height: 900px)": {
          //maxHeight: "calc(100vh - 335px)",
        },
        "& .simplebar-placeholder": {
          "@media (max-height: 699px)": {
            height: "auto !important",
            display: "none",
          },
        },
        "& .simplebar-mask, .simplebar-offset": {
          "@media (max-height: 699px)": {
            position: "static",
          },
        },
        "&.ps": {
          "& .ps__rail-x": {
            display: "none",
          },
          "& .ps__rail-y": {
            "@media (max-height: 699px)": {
              display: "none",
            },
          },
        },
      },
    },
    filterBox: {
      height: "80%",
    },
    selectHeader: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      "@media (max-width: 1199px)": {
        marginLeft: -10,
        marginRight: -10,
      },
    },
    divider: {
      backgroundColor: "#e9eaec",
      marginTop: 18,
      marginBottom: 10,
      "@media (max-width: 575px)": {
        marginTop: 10,
        marginBottom: 10,
      },
    },

    historyList: {
      overflowX: "hidden",
      //overflowY: "auto",
      height: "auto",
      //paddingRight: 6,
    },
    historyInnerList: {
      display: "flex",
      flexWrap: "wrap",
      height: "auto",
      marginTop: -12,
      marginLeft: -16,
      marginRight: -16,
      "@media (max-width: 575px)": {
        marginTop: -6,
        marginLeft: -8,
        marginRight: -8,
      },
    },
    navigation: {
      flex: "1 1 57px",
      padding: "20px 0 25px",
      "@media (max-width: 1199px)": {
        padding: "15px 0",
      },
      "@media (max-width: 575px)": {
        padding: "10px 0",
      },
      "& .MuiPagination-ul": {
        justifyContent: "center",
        "& li": {
          "&:not(:first-child):not(:last-child):not(:nth-child(2)):not(:nth-last-child(2))":
            {
              display: "none",
            },
        },
      },
    },
    boxNoTransaction: {
      width: "100%",
      textAlign: "center",
      "& h2": {
        fontSize: 24,
        fontWeight: 700,
        margin: "20px 0",
      },
    },
    totalTransaction: {
      fontWeight: "bold",
      paddingBottom: "8px",
    },
  })
);
