import React from 'react';

import { Typography, Box, TextField } from '@material-ui/core';
import Settings from '@material-ui/icons/Settings';
import { useStyles } from './styles';
import { setting_setting } from '../../graphql/Setting/__generated__/setting';

interface CostItemProps {
  title: string;
  text: string;
  priceT: number | null;
  onChange: (name: keyof setting_setting, value: number) => void;
  handleOnBlur: (value: keyof setting_setting) => void;
  loading: boolean;
  name: keyof setting_setting;
}

const CardSetting: React.FC<CostItemProps> = ({
  title,
  text,
  priceT,
  onChange,
  handleOnBlur,
  loading,
  name
}) => {
  const classes = useStyles();
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);
    if (!isNaN(newValue)) {
      onChange(name, newValue);
    }
  };
  return (
    <Box className={classes.root}>
      <Box className={classes.icon}>
        <Settings />
      </Box>
      <Box className={classes.text}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.value}>{text}</Typography>
      </Box>
      <Box className={classes.textFieldContainer}>
        <TextField
          className={classes.textField}
          variant="outlined"
          value={priceT ?? ''}
          onChange={handleInputChange}
          disabled={loading}
          onBlur={() => handleOnBlur(name)}
        />
      </Box>
    </Box>
  );
};

export default CardSetting;
