import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    "@global": {
      "* .MuiPopover-root .MuiPickersBasePicker-container": {},
    },
    selectDateItem: {
      marginRight: 20,
      width: 166,
      "@media (max-width: 1199px)": {
        marginRight: 0,
        paddingLeft: 10,
        paddingRight: 10,
        //width: "10rem",
        width: "33.33333%",
      },
      "@media (max-width: 767px)": {
        width: "50%",
      },
    },
    formControl: {
      fontFamily: "Poppins, sans-serif",
      height: 42,
      width: "100%",
      cursor: "pointer",
      "&:hover": {
        border: "none",
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#ffffff",
        borderRadius: 6,
        cursor: "pointer !important",
        paddingRight: 0,
        "&:hover, &:focus": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#B48A4E",
            outline: "none",
          },
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
          borderWidth: 1,
        },
      },
      "& .MuiIconButton-root": {
        padding: "9px 14px 9px",
      },
      "& .MuiOutlinedInput-input": {
        cursor: "pointer",
        fontSize: 12,
        // padding: "12px 0 11px 14px",
        "pointer-events": "none",
        "@media (max-width: 575px)": {
          //padding: "12px 0 11px 14px",
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      "& .MuiFormHelperText-contained": {
        margin: "0px 0px 0px 3px",
      },
      "& .MuiFormHelperText-root.Mui-error": {
        fontFamily: "Poppins, sans-serif",
        fontSize: ".625em",
      },
      "& .MuiInputBase-root.Mui-disabled": {
        color: "black",
        cursor: "default",
        height: 42,
      },
    },
    labeled_select: {
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: "Poppins, sans-serif !important",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "20px",
      margin: 0,
    },
  })
);
