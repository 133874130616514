import gql from 'graphql-tag';

export const COIN_ARCHIVE_OPTIONS = gql`
  query CoinArchiveOptions {
    coinArchiveOptions {
      id
      value
      label
    }
  }
`;
