import gql from 'graphql-tag';

export const UPDATE_SETTING = gql`
  mutation updateSetting($updateSettingInput: updateSettingInput) {
    updateSetting(updateSettingInput: $updateSettingInput) {
      id
      amountOfCreationCostLegalEntity
      amountOfCreationCostNaturalPerson
      amountOfValidationCost
      payoutAmount
      numberOfValidatorsLegalEntity
      numberOfValidatorsNaturalPerson
    }
  }
`;
