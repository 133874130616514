import React, { useEffect } from "react";
import Router from "./components/Router/Route";
import Cookies from "js-cookie";

const App = () => {
  useEffect(() => {
    const navigationType = (
      performance.getEntriesByType(
        "navigation"
      )[0] as PerformanceNavigationTiming
    ).type;

    if (
      (!Cookies.get("tabsOpen") &&
        parseInt(localStorage.getItem("tabsOpen") || "1") > 0) ||
      parseInt(Cookies.get("tabsOpen") || "1") <
        parseInt(localStorage.getItem("tabsOpen") || "1") ||
      (parseInt(Cookies.get("tabsOpen") || "1") ===
        parseInt(localStorage.getItem("tabsOpen") || "1") &&
        navigationType === "back_forward")
    ) {
      localStorage.clear();
    }

    const tabsOpen = localStorage.getItem("tabsOpen");

    if (tabsOpen == null) {
      localStorage.setItem("tabsOpen", "1");
      Cookies.set("tabsOpen", "1");
      window.sessionStorage.setItem("isReload", "true");
    } else {
      localStorage.setItem(
        "isFirstTab",
        parseInt(tabsOpen) === 0 &&
          (navigator.userAgent.indexOf("Firefox") > -1
            ? !window.sessionStorage.getItem("isReload")
            : navigationType !== "reload")
          ? "true"
          : "false"
      );
      localStorage.setItem("tabsOpen", (parseInt(tabsOpen) + 1).toString());
      Cookies.set(
        "tabsOpen",
        (parseInt(Cookies.get("tabsOpen") || "0") + 1).toString()
      );
      window.sessionStorage.setItem("isReload", "true");
    }

    window.onunload = (e: Event) => {
      const newTabCount = localStorage.getItem("tabsOpen");
      if (newTabCount !== null) {
        localStorage.setItem(
          "tabsOpen",
          (parseInt(newTabCount) - 1).toString()
        );
        Cookies.set(
          "tabsOpen",
          (parseInt(Cookies.get("tabsOpen") || "1") - 1).toString()
        );

        if (parseInt(newTabCount) === 2) {
          localStorage.setItem("isFirstTab", "false");
        }
      }
    };
  }, []);

  return <Router />;
};

export default App;
