import gql from 'graphql-tag';

export const ALL_COIN_OPTIONS = gql`
  query AllCoinOptions {
    allCoinOptions {
      value
      textName
    }
  }
`;
