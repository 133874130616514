import { Box, Typography } from '@material-ui/core';

import React, { useState } from 'react';
import { TEXT_SIGN_UP, TEXT_SIGN_UP_PERSON } from '../../../../constant';
import logoC4W from '../../../../assets/logo/logo_chain4test_complet.svg';
import CardSignUpPP from './cardSignUpP/cardSignUpPP';
import CardSignUpPM from './cardSignUpP/cardSignUpPM';
import { useStyles } from '../style';
interface signUpProps {
  lastName: string;
  firstName: string;
  id: string;
  password: string;
}
interface CardSinUpProps {
  setData: (value: any) => void;
}
const CardSignUp: React.FC<CardSinUpProps> = (props) => {
  const style = useStyles();
  const [choice, setChoice] = useState<boolean>(true);
  return (
    <Box className={style.root}>
      <Box className={style.cadre}>
        <div className={style.logo}>
          <img src={logoC4W} alt="C4W" />
        </div>
        <Typography variant="h4" className={style.textBienvenue}>
          Inscrivez-vous !
        </Typography>
        <Typography className={style.logintext}>{TEXT_SIGN_UP}</Typography>
        <Typography className={style.persontext}>
          {TEXT_SIGN_UP_PERSON}
        </Typography>
        <Box className={style.btnContainer}>
          <input
            type="checkbox"
            id="toggle"
            className={style.toggleCheckbox}
            checked={!choice}
            onChange={() => setChoice(!choice)}
          />
          <label htmlFor="toggle" className={style.toggleContainer}>
            <div className={style.toggleContainerDiv}>
              <Typography>PHYSIQUE</Typography>
            </div>
            <div className={style.toggleContainerDiv}>
              <Typography>MORALE</Typography>
            </div>
          </label>
        </Box>
        {choice ? <CardSignUpPP /> : <CardSignUpPM />}
      </Box>
    </Box>
  );
};

export default CardSignUp;
