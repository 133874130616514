import React from 'react';
import Header from '../acceuil/Header';
import { TEXT_CREATION } from '../../../constant';
import { Box } from '@material-ui/core';
import { useStyles } from './style';
import CreationPM from './creationPM/CreationPM';
import CreationPP from './creationPP/CreationPP';
import { getUser } from '../../../provider/localesStorage';
import { useQuery } from '@apollo/client';
import { setting } from '../../../graphql/Setting/__generated__/setting';
import { GET_SETTING } from '../../../graphql/Setting/query';
const Creation: React.FC = () => {
  const { data } = useQuery<setting>(GET_SETTING);
  const user = getUser();
  const style = useStyles();

  return (
    <Box className={style.rootContainer}>
      <Header
        {...{
          titleheader: 'Création & Validation',
          subtitle: 'Création et Validation de monnaie',
          texte: TEXT_CREATION,
        }}
      />
      {user?.legalEntity?.id ? <CreationPM data={data}/> : <CreationPP data={data}/>}
    </Box>
  );
};
export default Creation;
