import gql from 'graphql-tag';

export const LEGAL_ENTITY_TYPES = gql`
  query legalEntityTypes {
    legalEntityTypes {
      id
      label
    }
  }
`;
