import { Button } from "@material-ui/core";
import React, { FC } from "react";

interface buttonProps {
  style?: React.CSSProperties | undefined;
  variant?: "text" | "outlined" | "contained";
  color?: "inherit" | "primary" | "secondary";
  inputprops?: any;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  nameBtn: string;
  className?: string;
  disabled?: boolean;
}

export const CustomButton: FC<buttonProps> = ({
  style,
  variant,
  color,
  children,
  startIcon,
  endIcon,
  onClick,
  nameBtn,
  className,
  disabled,
  ...inputprops
}) => {
  return (
    <Button
      {...{
        style: style,
        variant: variant,
        color: color,
        startIcon,
        endIcon,
        onClick,
        inputprops,
        className,
        disabled: disabled,
      }}
    >
      {nameBtn}
      {children}
    </Button>
  );
};
