import { gql } from "@apollo/client";
import { TRANSACTION_HISTORY_FRAGMENT_INFOS } from "../TransactionHistory/fragment";

export const TRANSACTION_FRAGMENTS_INFO = gql`
  fragment transactionInfo on TransactionResult {
    total
    data {
      ...transactionHistoryFragmentInfos
    }
  }
  ${TRANSACTION_HISTORY_FRAGMENT_INFOS}
`;
