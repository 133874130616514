import gql from "graphql-tag";
import { TRANSACTION_HISTORY_FRAGMENT_INFOS } from "../TransactionHistory/fragment";

export const DO_MAKE_TRANSACTION = gql`
  mutation MAKE_TRANSACTION($transactionInput: TransactionInput) {
    makeTransaction(transactionInput: $transactionInput) {
      ...transactionHistoryFragmentInfos
    }
  }
  ${TRANSACTION_HISTORY_FRAGMENT_INFOS}
`;
