import { gql } from '@apollo/client';

export const DO_GET_COMMISSIONNIG_COINS = gql`
  query do_get_commissionning_coins {
    commissioningCoins {
      id
      currency
      code
      shortCode
      color
      isValidated
      expired
      ownerId
      creator
      validatorCount
      validatorRequired
      canRepay
      createdAt
    }
  }
`;
