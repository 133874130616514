/* eslint-disable no-use-before-define */
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { ChangeEvent, FC, ReactNode } from "react";

interface autocompleteProps {
  className: string;
  options: any[];
  optionLabelKey: string;
  inputValue: any;
  id?: string;
  loading?: boolean;
  loadingText?: ReactNode;
  noOptionsText?: string;
  disableClearable?: boolean;
  autoSelect?: boolean;
  shrink?: boolean;
  disabled?: boolean;
  popupIcon?: ReactNode;
  onChange: (event: ChangeEvent<any>, inputValue: any) => void;
  renderOption?: (option: any, state?: any) => ReactNode;
  onOpen?: (event: object) => void;
  variant?: "standard" | "filled" | "outlined";
  label?: string;
  placeholder?: string;
  name?: string;
  open?: boolean;
  inputLabel?: string;
  inputLabelStyle?: string;
  optionStyle?: string;
}
const CustomAutoComplete: FC<autocompleteProps> = ({
  options,
  optionLabelKey,
  inputValue,
  popupIcon,
  onChange,
  loading,
  loadingText,
  noOptionsText,
  disableClearable,
  autoSelect,
  shrink,
  className,
  disabled,
  renderOption,
  onOpen,
  variant,
  label,
  placeholder,
  id,
  name,
  open,
  inputLabel,
  inputLabelStyle,
  optionStyle,
  ...props
}) => {
  return (
    <Autocomplete
      freeSolo
      onOpen={onOpen}
      open={open}
      className={className}
      id={id}
      disableClearable
      options={options}
      getOptionLabel={(option: any) => option[`${optionLabelKey}`]}
      onChange={onChange}
      onInputChange={(e, value) => onChange(e, value)}
      inputValue={inputValue}
      renderInput={(params) => (
        <>
          {inputLabel && (
            <Typography variant="h6" className={inputLabelStyle}>
              {inputLabel}
            </Typography>
          )}
          <TextField
            {...params}
            {...props}
            label={label}
            placeholder={placeholder}
            margin="normal"
            variant="outlined"
            name={name}
            InputProps={{ ...params.InputProps, type: "search" }}
          />
        </>
      )}
      renderOption={(option, state) => {
        const { label } = option;
        return (
          <span className={optionStyle}>{label}</span>
        );
      }}
    />
  );
};
CustomAutoComplete.defaultProps = {
  loading: true,
  loadingText: "Chargement...",
  noOptionsText: "Aucun résultat",
  disableClearable: true,
  autoSelect: true,
  shrink: true,
};
export default CustomAutoComplete;
