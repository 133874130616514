import React, { createContext, FC, useMemo, useState } from "react";
import { GET_BALANCE_balances } from "../graphql/Balances/__generated__/GET_BALANCE";

interface BalanceContextInterface {
  balance: (GET_BALANCE_balances | null)[];
  setBalance: (value: (GET_BALANCE_balances | null)[]) => void;
}

export const BalanceContext = createContext<BalanceContextInterface>({
  balance: [],
  setBalance: () => {},
});
export const BalanceProvider: FC = ({ children }) => {
  const [balance, setBalance] = useState<any>();
  const value = useMemo<BalanceContextInterface>(
    () => ({ balance, setBalance }),
    [balance]
  );

  return (
    <BalanceContext.Provider value={value}>{children}</BalanceContext.Provider>
  );
};
