import { Redirect, Route } from "react-router-dom";
import { LOGIN_URL } from "../../constant/url";

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !!localStorage.getItem("token") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: LOGIN_URL, state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
