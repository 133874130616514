import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import React, { FC } from "react";
import { useStyles } from "./style";

export interface SimpleDialogProps {
  open: boolean;
  title?: string;
  titleActionButton?: string;
  singleActionButton?: boolean;
  setOpen: (value: any) => void;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  maxWidth?: false | "sm" | "xs" | "md" | "lg" | "xl";
  className?: string | undefined;
}

export const CustomDialog: FC<SimpleDialogProps> = ({
  open,
  setOpen,
  title,
  titleActionButton,
  singleActionButton,
  onClick,
  maxWidth,
  children,
  className,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#B48A4E",
      },
    },
  });

  const style = useStyles();
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={maxWidth}
      fullWidth={true}
      className={className}
    >
      <DialogTitle>
        <Box className={style.titledialog}>
          <Box>{title && <Typography variant="h6">{title}</Typography>}</Box>
          <IconButton aria-label="close" onClick={handleClose}>
            <Close color="action" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions className={style.actiondialog}>
        <Box>
          {singleActionButton && (
            <Button
              onClick={handleClose}
              className={clsx(style.btndialog, style.btnCancel)}
              variant="outlined"
            >
              Annuler
            </Button>
          )}
          <ThemeProvider theme={theme}>
            <Button
              onClick={onClick}
              color="primary"
              variant="contained"
              size="large"
              className={clsx(style.btndialog, style.btnconfirmer)}
            >
              {titleActionButton ? titleActionButton : " CONFIRMER"}
            </Button>
          </ThemeProvider>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
