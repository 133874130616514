import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import React, { FC } from 'react';
import { useStyles } from './style';

export interface SimpleDialogProps {
  open: boolean;
  title?: string;
  setOpen: (value: any) => void;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  maxWidth?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl';
  className?: string | undefined;
}

export const CustomModal: FC<SimpleDialogProps> = ({
  open,
  setOpen,
  title,
  maxWidth,
  children,
  className,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#B48A4E',
      },
    },
  });

  const style = useStyles();
  return (
    <Dialog
      onClose={() => {} } 
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={maxWidth}
      fullWidth={true}
      className={className}
      disableEscapeKeyDown 
    >
      <DialogTitle>
        <Box className={style.titledialog}>
          <Box>{title && <Typography variant="h6">{title}</Typography>}</Box>
          <IconButton aria-label="close" onClick={handleClose}>
            <Close color="action" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={style.contentdialog}>{children}</DialogContent>
      <DialogActions className={style.actiondialog}>
        <Box>
          <Button
            onClick={handleClose}
            className={style.btnCancel}
            variant="outlined"
          >
            FERMER
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
