import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface StyleProps {
  width?: string;
}

export const useStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    redBorderRounded: (props) => ({
      border: '2px solid #db4f4fd7',
      borderRadius: '8px',
      padding: '16px',
      width: props.width || '100%',
      maxWidth: '100%',
      overflow: 'hidden',
      '@media (max-width: 1600px)': {
        padding: '14px',
        borderRadius: '6px',
      },
      '@media (max-width: 1366px)': {
        padding: '12px',
        borderRadius: '4px',
      },
      '@media (max-width: 1024px)': {
        padding: '10px',
        borderRadius: '3px',
      },
    }),
    typographyContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      color: '#db4f4fd7',
      fontSize: '18px',

      '@media (max-width: 1600px)': {
        fontSize: '16px',
      },
      '@media (max-width: 1366px)': {
        fontSize: '14px',
      },
      '@media (max-width: 1024px)': {
        fontSize: '12px',
      },
    },
    textContent: {
      fontSize: '18px',

      '@media (max-width: 1600px)': {
        fontSize: '16px',
      },
      '@media (max-width: 1366px)': {
        fontSize: '14px',
      },
      '@media (max-width: 1024px)': {
        fontSize: '12px',
      },
    },
    typographyIcon: {
      fontSize: '20px',

      '@media (max-width: 1600px)': {
        fontSize: '18px',
      },
      '@media (max-width: 1366px)': {
        fontSize: '16px',
      },
      '@media (max-width: 1024px)': {
        fontSize: '14px',
      },
    },
  }),
);
