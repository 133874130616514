import { useMutation } from '@apollo/client/react/hooks/useMutation';
import {
  Box,
  createMuiTheme,
  TextField,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { CustomBackdrop } from '../../../common/BackDrop/BackDrop';
import { CustomButton } from '../../../common/CustomButton/CustomButton';
import { CustomSnackBar } from '../../../common/CustomSnackBar/CustomSnackBar';
import { TEXT_LOGIN, TOKEN } from '../../../constant';
import {
  ACCUEIL_URL,
  COMMISSIONING_URL,
  SIGN_UP_URL,
} from '../../../constant/url';
import { DO_SIGNIN } from '../../../graphql/Login/mutation';
import { AuthContext } from '../../../provider/Authprovider';
import { getUser } from '../../../provider/localesStorage';
import { useStyles } from './style';
import logoC4W from '../../../assets/logo/logo_chain4test_complet.svg';
import { login, loginVariables } from '../../../graphql/Login/__generated__/login';

interface loginProps {
  id: string;
  password: string;
}

const Homepage: React.FC = () => {
  const { login, logout, setToken, setUserInfos } = useContext(AuthContext);
  const user = getUser();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string>('');
  const [type, setType] = useState<string>('');
  const style = useStyles();
  const [activ, setactiv] = useState<boolean>(false);
  const [idError, setIdError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  let history = useHistory();
  const [input, setInput] = useState<loginProps>({
    id: '',
    password: '',
  });

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#B48A4E',
      },
    },
  });

  const [doSignIn, { loading: doSignInLoading }] = useMutation<
    login,
    loginVariables
  >(DO_SIGNIN, {
    onCompleted: (data) => {
      setOpen(true);
      setType('success');
      setMessage('Authentification reussi');
      if (data.login) {
        login(data.login);
      }
      if (data.login.userPartialInfos?.isAdmin) {
        history.push(COMMISSIONING_URL);
      } else {
        history.push(ACCUEIL_URL);
      }
    },
    onError: (errors) => {
      setOpen(true);
      setType('error');
      if (input.id === '' || input.password === '') {
        setMessage('Veuillez completer tous les champs!');
      } else {
        errors?.graphQLErrors?.map((error) => {
          setMessage(error.message);
          return error;
        });
      }
    },
  });

  const handleClick = () => {
    const { id, password } = input;
    if (id.length < 6 || id.length > 20) {
      setOpen(true);
      setType('error');
      setMessage(
        'L’identifiant de connexion choisi est incorrect : 6 à 20 caractères obligatoires !',
      );
    } else if (isNaN(+password) || password.length !== 6) {
      setOpen(true);
      setType('error');
      setMessage(
        'Le mot de passe choisi est incorrect : 6 chiffres obligatoires !',
      );
    } else {
      doSignIn({
        variables: {
          userLoginInput: {
            id: id.trim() || '',
            password: password || '',
          },
        },
      });
    }
  };

  const onKeyPressed = (e: any) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = String(event.target.value);
    setInput((prevstate) => ({ ...prevstate, [name]: value }));
  };

  useEffect(() => {
    if (input.id !== '' || input.password !== '') {
      setactiv(true);
    } else setactiv(false);
  }, [input]);

  useEffect(() => {
    const token = localStorage.getItem(TOKEN);
    if (!!token && user) {
      if (localStorage.getItem('isFirstTab') === 'true') {
        logout();
      } else {
        setToken(token);
        setUserInfos({
          isAdmin: user.isAdmin,
          userName: user.userName,
        })
        if (user.isAdmin) {
          history.push(COMMISSIONING_URL);
        } else {
          history.push(ACCUEIL_URL);
        }
      }
    }
  }, []);

  return (
    <Box className={style.root}>
      <Box className={style.cadre}>
        <div className={style.logo}>
          <img src={logoC4W} alt="C4W" />
        </div>
        <Typography variant="h4" className={style.textBienvenue}>
          Bienvenue !
        </Typography>
        <Typography className={style.logintext}>{TEXT_LOGIN}</Typography>
        <TextField
          {...{
            className: style.textField,
            name: 'id',
            label: 'Votre identifiant',
            type: 'text',
            variant: 'outlined',
            onKeyDown: (e) => onKeyPressed(e),
            onChange: (e) => handleChange(e),
            onFocus: () => setIdError(true),
            onBlur: () => setIdError(false),
            error: idError && (input.id.length < 6 || input.id.length > 20),
            helperText:
              idError && (input.id.length < 6 || input.id.length > 20)
                ? '6 à 20 caractères !'
                : '',
          }}
        />
        <TextField
          {...{
            name: 'password',
            className: style.textField,
            label: 'Votre mot de passe',
            type: 'password',
            variant: 'outlined',
            onKeyDown: (e) => onKeyPressed(e),
            onChange: (e) => handleChange(e),
            onFocus: () => setPasswordError(true),
            onBlur: () => setPasswordError(false),
            error:
              passwordError &&
              (isNaN(+input.password) || input.password.length !== 6),
            helperText:
              passwordError &&
              (isNaN(+input.password) || input.password.length !== 6)
                ? '6 chiffres !'
                : '',
          }}
        />
        <ThemeProvider theme={theme}>
          <CustomButton
            {...{
              nameBtn: 'SE CONNECTER',
              color: 'primary',
              variant: 'contained',
              className: style.btnconnect,
              onClick: handleClick,
              disabled: !activ,
            }}
          />
        </ThemeProvider>

        <NavLink to={SIGN_UP_URL} className={style.link}>
          <Typography variant="subtitle1">
            Je n'ai pas d'identifiants de connexion
          </Typography>
        </NavLink>
      </Box>
      <CustomSnackBar
        {...{ open: open, setOpen: setOpen, message: message, type: type }}
      />
      <CustomBackdrop {...{ open: doSignInLoading }} />
    </Box>
  );
};

export default Homepage;
