import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    boxFaqContent: {
      padding: '32px 10px 32px 10px',
      height: '100%',
    },
    faqTitle: {
      font: 'normal normal 600 34px/36px Poppins',
      color: '#4B7FD5',
    },
    boxMarkdown: {
      paddingRight: '9vw',
      paddingBottom: '3vw',
      overflow: 'hidden',
      fontSize: '17px',
      // "&::-webkit-scrollbar": {
      //   display: "none"
      // }
      '& ul': {
        marginTop: -10, 
      },
      '& h4': {
        marginBottom: ' 10px',
        margin: '0',
      },
      '& p': {
        margin: '0px 0px 14px',
        fontFamily: 'Poppins, sans-serif',
      },
    },
    rootContainer: {
      marginTop: 100,
      padding: '22px 28px',
      width: '100%',
      '@media (max-width: 575px)': {
        padding: '11px 14px',
      },
    },
  }),
);
