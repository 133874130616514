import { gql } from "@apollo/client";
import { TRANSACTION_FRAGMENTS_INFO } from "./fragment";

export const GET_TRANSACTIONS = gql`
  query TRANSACTIONS($args: GetTransactionArgs) {
    transactions(getTransactionArgs: $args) {
      ...transactionInfo
    }
  }
  ${TRANSACTION_FRAGMENTS_INFO}
`;
