import gql from 'graphql-tag';
import { TRANSACTION_HISTORY_FRAGMENT_INFOS } from '../TransactionHistory/fragment';

export const CREATE_COIN = gql`
  mutation createCoin($createCoinInput: createCoinInput) {
    createCoin(createCoinInput: $createCoinInput) {
      transactionHistories {  
        ...transactionHistoryFragmentInfos
      }
    }
  }
  ${TRANSACTION_HISTORY_FRAGMENT_INFOS}
`;
