import React, { FC } from 'react';
import { Box, CardContent, Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { useStyles } from './style';

interface IPropsCardNotice {
  width?: string;
  children: React.ReactNode;
}

export const CardNotice: FC<IPropsCardNotice> = ({ children, width }) => {
  const style = useStyles({ width });

  return (
    <Box>
      <CardContent className={style.redBorderRounded}>
        <Typography className={style.typographyContainer} gutterBottom>
          <Error className={style.typographyIcon} />
          Note :
        </Typography>
        <Typography className={style.textContent} variant="body2">
          {children}
        </Typography>
      </CardContent>
    </Box>
  );
};

export default CardNotice;
