import gql from 'graphql-tag';

export const GET_SETTING = gql`
  query setting {
    setting {
      id
      amountOfCreationCostLegalEntity
      amountOfValidationCost
      payoutAmount
      numberOfValidatorsLegalEntity
      numberOfValidatorsNaturalPerson
      amountOfCreationCostNaturalPerson
    }
  }
`;
