import {
  Box,
  Typography,
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { TEXT_ACCES, TEXT_INTRO_ACCES } from '../../../../constant';
import logoC4W from '../../../../assets/logo/logo_chain4test_complet.svg';
import { useStyles } from '../style';
import { useHistory, useLocation } from 'react-router-dom';
import { CustomButton } from '../../../../common/CustomButton/CustomButton';
import { useMutation } from '@apollo/client';
import { DO_SIGNIN } from '../../../../graphql/Login/mutation';
import { ACCUEIL_URL } from '../../../../constant/url';
import { AuthContext } from '../../../../provider/Authprovider';
import { CustomSnackBar } from '../../../../common/CustomSnackBar/CustomSnackBar';
import { CustomBackdrop } from '../../../../common/BackDrop/BackDrop';
import { login, loginVariables } from '../../../../graphql/Login/__generated__/login';
const CardSignUpID: React.FC = () => {
  const { login } = useContext(AuthContext);
  const style = useStyles();
  const location = useLocation<{ identifiant: string; password: string }>();
  const { identifiant, password } = location.state || {};
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string>('');
  const [type, setType] = useState<string>('');
  const history = useHistory();
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#B48A4E',
      },
    },
  });
  const [doSignIn, { loading: doSignInLoading }] = useMutation<
    login,
    loginVariables
  >(DO_SIGNIN, {
    onCompleted: (data) => {
      setOpen(true);
      setType('success');
      setMessage('Authentification reussi');
      if (data.login) {
        login(data.login);
        history.push(ACCUEIL_URL);
      }
    },
    onError: (errors) => {
      setOpen(true);
      setType('error');
      setMessage('Nous rencontrons un problème ');
    },
  });
  const handleClick = () => {
    if (identifiant !== null && password !== null) {
      console.log('erreur ');
      doSignIn({
        variables: {
          userLoginInput: {
            id: identifiant,
            password: password,
          },
        },
      });
    }
  };

  return (
    <Box className={style.root}>
      <Box className={style.cadre}>
        <div className={style.logo}>
          <img src={logoC4W} alt="C4W" />
        </div>
        <Typography variant="h4" className={style.textBienvenue}>
          {TEXT_ACCES}
        </Typography>
        <Typography className={style.logintext}>{TEXT_INTRO_ACCES}</Typography>
        <Box className={style.cardAccess}>
          <Box className={style.item}>
            <Typography className={style.label}>Votre identifiant:</Typography>
            <Typography className={style.value}>{identifiant}</Typography>
          </Box>
          <Box className={style.item}>
            <Typography className={style.label}>Votre mot de passe:</Typography>
            <Typography className={style.value}>{password}</Typography>
          </Box>
        </Box>
        <ThemeProvider theme={theme}>
          <CustomButton
            {...{
              //   disabled: !activ,
              color: 'primary',
              nameBtn: 'FERMER',
              variant: 'contained',
              onClick: handleClick,
              className: style.btnconnect,
            }}
          />
        </ThemeProvider>
      </Box>
      <CustomSnackBar
        {...{ open: open, setOpen: setOpen, message: message, type: type }}
      />
      <CustomBackdrop {...{ open: doSignInLoading }} />
    </Box>
  );
};

export default CardSignUpID;
