import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useStyles } from "../homepage/style";
import CardSignUp from "./cardSignUp/CardSignUp";
import CardSignUpAccess from "./cardAccess/CardSignUpAccess";

interface signUpResult {
  id: string;
  password: string;
  duplicatedName: string;
}

const SignUpPage: React.FC = () => {
  const style = useStyles();
  const [data, setData] = useState<signUpResult | null>(null);

  return (
    <Box className={style.root}>
      {!data ? (
        <CardSignUp setData={setData} />
      ) : (
        <CardSignUpAccess
          id={data.id}
          password={data.password}
          duplicatedName={data.duplicatedName}
        />
      )}
    </Box>
  );
};

export default SignUpPage;
