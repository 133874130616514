import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    titledialog: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#fff',
    },

    actiondialog: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    btndialog: {
      boxShadow: 'none',
      fontSize: 15,
      height: 42,
      letterSpacing: 0.57,
      lineHeight: '16px',
      textAlign: 'center',
      margin: '0 6px',
      padding: 10,
      width: 190,
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
    btnCancel: {
      // backgroundColor: '#e7cb59af',
      // // borderColor: '#B48A4E',
      // borderWidth: 1,
      // color: '#6f4d1d',
      // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#e7cb59af',
      borderColor: '#B48A4E',
      borderWidth: ' 1px',
      borderStyle: 'solid',
      color: '#6f4d1d',
      boxShadow: '0 3px 3px rgb(0 0 0 / 51%)',
    },
    btnconfirmer: {
      backgroundColor: '#B48A4E',
      color: '#fff',
      boxShadow: '0 3px 3px rgb(0 0 0 / 51%)',
    },
  }),
);
