import { useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { USER_OPTIONS } from "../../../../graphql/autocompleteOptions/userOptions";
import { User_Options } from "../../../../graphql/autocompleteOptions/__generated__/User_Options";

export const UseTransfert = () => {
  const [errorMessage, setErrorMessage] = useState<string>(
    "impossible d'afficher les listes des utilisateurs"
  );
  const [
    listUsers,
    { data: options, loading: userLoading, error: errorUser },
  ] = useLazyQuery<User_Options>(USER_OPTIONS, {
    variables: {},
    onError: (error) => {
      if (error.networkError) {
        setErrorMessage("Veuillez vérifier le réseau");
      }
      if (error.graphQLErrors) {
        setErrorMessage(error.message);
      }
    },
  });

  return { listUsers, options, userLoading, errorUser, errorMessage };
};
