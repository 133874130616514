import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    transferContainer: {
      border: '1px solid #E9EAEC',
      borderRadius: 6,
      height: 'auto',
      maxWidth: '100%',
      margin: '185px auto 0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '40px 20px',
      width: 'calc(100% - 1px)',
      '& *': {
        boxSizing: 'border-box',
      },
      '@media (max-width: 768px)': {
        margin: '220px auto 0',
      },
    },
    containtWrappe: {
      width: '60%',
      maxWidth: '1000px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 8,
      '@media (max-width: 1366px)': {
        width: '90%',
      },
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: 20,
      flexWrap: 'wrap', // Ajouté pour permettre aux éléments de s'adapter
    },
    cardNotice: {
      marginLeft: 195,
      width: '100%',
      '@media (max-width: 1600px)': {
        marginLeft: '10%',
      },
      '@media (max-width: 1366px)': {
        marginLeft: '24% !important',
      },
      '@media (max-width: 1023px)': {
        marginLeft: 0,
      },
    },
    typography: {
      fontWeight: 600,
      marginRight: '10px',
      fontSize: 20,
    },
    textField: {
      width: 500,
      background: '#FFFFFF',
      opacity: 1,
      height: 45,
      margin: 0,
      '@media (max-width: 1366px)': {
        width: '100%', // Adapter la largeur pour éviter le débordement
      },
      '&:last-of-type': {
        marginBottom: 0,
      },
      '& label': {
        color: '#A8AAB3',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
      },
      '& .MuiOutlinedInput-root': {
        '&:hover': {
          borderColor: '#B48A4E',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#B48A4E',
          },
        },
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 14px) scale(1)', // Position initiale
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -10px) scale(0.75)', // Ajustement vertical
        },
      },
      '& .MuiInputLabel-formControl': {
        fontFamily: 'Poppins, sans-serif',
      },
      '& .MuiOutlinedInput-input': {
        height: 45,
        paddingLeft: 10,
        width: '100%', // Adapter la largeur pour éviter le débordement
      },
      '& input': {
        borderRadius: 6,
        fontSize: '1.1em',
        '&:focus': {
          outline: 'none',
        },
      },
    },
    textPM: {
      fontSize: 20,
      marginBottom: 20,
      padding: '5px 60px',
      '@media (max-width: 1366px)': {
        fontSize: 18, // Ajustement pour petits écrans
      },
    },
    textPP: {
      fontSize: 20,
      marginBottom: 20,
      marginLeft: 60,
      padding: '5px 60px',
      '@media (max-width: 1366px)': {
        fontSize: 18,
      },
      '@media (max-width: 992px)': {
        marginLeft: 0, 
        padding: '5px 10px',
      },
    },
    btndialog: {
      boxShadow: 'none',
      fontSize: 15,
      height: 42,
      letterSpacing: 0.57,
      lineHeight: '16px',
      textAlign: 'center',
      margin: '0 6px',
      padding: 10,
      width: 190,
      '@media (max-width: 1366px)': {
        width: '100%', // Adapter la largeur
      },
    },
    btnConfirmer: {
      backgroundColor: '#B48A4E',
      color: '#fff',
      boxShadow: '0 3px 3px rgb(0 0 0 / 51%)',
      padding: '10px 20px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: 300,
      marginBottom: 20,
      '@media (max-width: 1366px)': {
        width: '100%', // Adapter la largeur
      },
    },
    redBorderRounded: {
      border: '2px solid #db4f4fd7',
      borderRadius: '8px',
      padding: '16px',
      width: '65%',
      '@media (max-width: 1366px)': {
        width: '100%', // Adapter la largeur
      },
    },
    btnContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    toggleCheckbox: {
      display: 'none',
      '&:checked + $toggleContainer::before': {
        left: '50%',
      },
      '&:checked + $toggleContainer div:first-child': {
        color: '#B48A4E',
        transition: 'color 0.3s',
      },
      '&:checked + $toggleContainer div:last-child': {
        color: 'white',
        transition: 'color 0.3s',
      },
      '& + $toggleContainer div:first-child': {
        color: 'white',
        transition: 'color 0.3s',
      },
      '& + $toggleContainer div:last-child': {
        color: '##B48A4E',
        transition: 'color 0.3s',
      },
    },
    toggleContainer: {
      position: 'relative',
      display: 'flex',
      width: '369px',
      borderRadius: 30,
      background: '#eeebebff',
      fontWeight: 'bold',
      color: '#343434',
      cursor: 'pointer',
      padding: 0,
      marginBottom: '26px',
      overflow: 'hidden',
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '50%',
        height: '100%',
        left: '0%',
        borderRadius: 30,
        background: '#B48A4E',
        transition: 'all 0.3s',
      },
      '@media (max-width: 1366px)': {
        width: '100%', // Adapter la largeur
      },
    },
    toggleContainerDiv: {
      flex: 1,
      padding: 14,
      textAlign: 'center',
      zIndex: 1,
      color: '#B48A4E' /* Définir la couleur du texte */,
    },
    codeCurrency: {
      color: '#B48A4E',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
    }
  }),
);
