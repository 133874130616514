import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';
import { useStyles } from './style';
import TransactionItem from '../../../common/cardMoney/TransactionItem';
import Setting from './Setting/Setting';
import { useMutation, useQuery } from '@apollo/client';
import { DO_GET_COMMISSIONNIG_COINS } from '../../../graphql/CommissioningCoins/query';
import {
  do_get_commissionning_coins,
  do_get_commissionning_coins_commissioningCoins,
} from '../../../graphql/CommissioningCoins/__generated__/do_get_commissionning_coins';
import Header from '../acceuil/Header';
import {
  putIntoCirculation,
  putIntoCirculationVariables,
} from '../../../graphql/PutIntoCirculation/__generated__/putIntoCirculation';
import { PUT_INTO_CIRCULATION } from '../../../graphql/PutIntoCirculation/mutation';
import { CustomModal } from '../../../common/customModal/CustomModal';

const Supply: React.FC = () => {
  const [coins, setCoins] = useState<
    do_get_commissionning_coins_commissioningCoins[]
  >([]);
  const [idCoins, setIdCoins] = useState<string[]>([]);
  const [message, setMessage] = useState<string>('');

  const [open, setOpen] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const classes = useStyles();

  const { data, loading, error, refetch } =
    useQuery<do_get_commissionning_coins>(DO_GET_COMMISSIONNIG_COINS);

  const [
    putIntoCirculationMutation,
    { loading: loadingPutCirculation, error: errorPutCirculation },
  ] = useMutation<putIntoCirculation, putIntoCirculationVariables>(
    PUT_INTO_CIRCULATION,
    {
      onCompleted: () => {
        setMessage('Mise en service effectuée avec succès');
        setOpen(true);
      },
      onError: (errors) => {
        setMessage('un problème a été rencontré');
        setOpen(true);
      },
    },
  );

  const handleChildData = (id: string): void => {
    if (idCoins !== null) {
      if (!idCoins.includes(id)) {
        setIdCoins([...idCoins, id]);
      }
    }
  };
  const deleteChildData = (id: string): void => {
    if (idCoins !== null) {
      // Vérifier si l'ID existe dans le tableau
      if (idCoins.includes(id)) {
        // Supprimer l'ID du tableau en filtrant les autres éléments
        setIdCoins(idCoins.filter((coinId) => coinId !== id));
      }
    }
  };
  const handleClick = async () => {
    if (idCoins.length > 0) {
      try {
        const response = await putIntoCirculationMutation({
          variables: {
            putIntoCirculationInput: {
              coinIds: idCoins,
            },
          },
        });
        refetch();
        console.log('Mutation réussie : ', response.data);
      } catch (err) {
        console.error('Erreur lors de l’exécution de la mutation : ', err);
      }
    } else {
      console.log('vide...');
    }
  };
  useEffect(() => {
    if (data?.commissioningCoins) {
      const validCoins = data.commissioningCoins.filter(
        (coin): coin is do_get_commissionning_coins_commissioningCoins =>
          coin !== null,
      );
      setCoins(validCoins);
    }
    refetch();
    const handleChildData = (id: string): void => {
      if (idCoins !== null) {
        setIdCoins([...idCoins, id]);
      }
    };
  }, [data, reload]);

  return (
    <Box className={classes.rootContainer}>
      <Header titleheader="Mise en service" subtitle="" texte="" />
      <CustomModal open={open} setOpen={setOpen} maxWidth="sm">
        <Box>{message}</Box>
      </CustomModal>
      <Box className={classes.container}>
        <Box className={classes.columnBox}>
          <Box  className={classes.headerMenu}>
            <Box className={classes.item}>
              <Typography className={classes.titleItem}>
                Liste des monnaies en cours
              </Typography>
              <Typography>Valider les monnaies en cours de création</Typography>
            </Box>
            <Box className={classes.item}>
              <Box className={classes.groupeitem}>
                <Typography>Action groupée:</Typography>
                <Box>
                  <Button
                    className={classes.btnCancel}
                    variant="outlined"
                    onClick={handleClick}
                  >
                    REVERSEMENT
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                  {loading ? (
                    <Typography>Chargement...</Typography>
                  ) : error ? (
                    <Typography color="error">
                      Erreur de Chargement données.
                    </Typography>
                  ) : coins.length > 0 ? (
                    coins.map((coin, index) => (
                      <TransactionItem
                        key={index}
                        amount={coin.validatorCount || 0}
                        idUser={coin.creator}
                        date={coin.createdAt}
                        idCoin={coin.id || ''}
                        color={coin.color}
                        shortCode={coin.shortCode}
                        title={coin.code || 'N/A'}
                        validatorCount={coin.validatorCount || 0}
                        validatorRequired={coin.validatorRequired || 0}
                        refetch={refetch}
                        handleChildCheck={handleChildData}
                        deleteChildCheck={deleteChildData}
                        setOpen={setOpen}
                        setMessage={setMessage}
                      />
                    ))
                  ) : (
                    <Typography>Aucun coin .</Typography>
                  )}
              </CardContent>
          </Card>
        </Box>
        <Box className={classes.columnBox}>
          <Box className={classes.item}>
              <Typography className={classes.titleItem}>Paramètrages</Typography>
              <Typography>
                Paramètrer les conditions dans cette section
              </Typography>
          </Box>
          <Card className={classes.card1}>
              <Setting setReload={setReload}/>
            </Card>
        </Box>
      </Box>
    </Box>
   
  );
};

export default Supply;
