import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";
import { useStyles } from "./style";

interface RadioButtonProps {
  className?: string | undefined;
  arialabel?: string;
  titregroup: string;
  name?: string;
  value?: any;
  row?: boolean;
  onChange?:
    | ((event: React.ChangeEvent<HTMLInputElement>, value: any) => void)
    | undefined;
  valueOption: {
    value: string;
    label: any;
    asterisk?: number;
  }[];
}

interface controlProps {
  value?: unknown;
  label: string;
}

export const ControlFormLabel: FC<controlProps> = ({ value, label }) => {
  return <FormControlLabel value={value} control={<Radio />} label={label} />;
};

export const CustomRadioButton: FC<RadioButtonProps> = ({
  onChange,
  name,
  value,
  titregroup,
  arialabel,
  valueOption,
  row,
  className,
}) => {
  const style = useStyles();
  return (
    <FormControl component="fieldset">
      <Typography className={style.labeled}>{titregroup}</Typography>
      <RadioGroup
        className={className}
        aria-label={arialabel}
        name={name}
        value={value}
        onChange={onChange}
        row={row}
      >
        {valueOption.map((element) => {
          return (
            <FormControlLabel
              key={element.label}
              value={element.value}
              control={<Radio />}
              label={
                <span>{element.label}
                  {element.asterisk === 1 && <Typography component="span" className={style.asterisk}>
                  &nbsp;*
                  </Typography>}
                  {element.asterisk === 2 && <Typography component="span" className={style.asterisk}>
                  &nbsp;**
                  </Typography>}
                </span>
              }
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
