import gql from 'graphql-tag';

export const USER_OPTIONS = gql`
  query User_Options {
    userOptions {
        id
        value
        label
    }
  }
`;
