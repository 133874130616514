import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    titledialog: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#fff',
    },
    actiondialog: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '10px 15px',
    },
    btnCancel: {
      fontSize: 15,
      height: 42,
      letterSpacing: 0.57,
      lineHeight: '16px',
      textAlign: 'center',
      margin: '10px 6px',
      padding: 10,
      backgroundColor: 'rgb(255, 235, 162)',
      color: 'rgb(180, 138, 78)',
      width: 466,
      boxShadow: '0 4px 0px rgb(0 0 0 / 55%)',
    },
    contentdialog: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      flexDirection: 'column',
      minHeight: '100px', // optionnel, ajuste la hauteur minimum
    },
  }),
);
