import { FC, useState } from 'react';
import { Typography, Button, Box, Checkbox } from '@material-ui/core';
import { useStyles } from './styles';
import { MdCalendarViewMonth } from 'react-icons/md';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useMutation } from '@apollo/client';
import {
  putIntoCirculation,
  putIntoCirculationVariables,
} from '../../graphql/PutIntoCirculation/__generated__/putIntoCirculation';
import { PUT_INTO_CIRCULATION } from '../../graphql/PutIntoCirculation/mutation';
import Pictogram from '../CustomPithogramme/Pithogramme';

interface TransactionItemProps {
  title: string | null;
  date: string | null;
  amount: number | null;
  idUser: string | null;
  idCoin: string | null;
  color: string | null;
  shortCode: string | null;
  validatorCount: number;
  validatorRequired: number;
  refetch: () => void;
  handleChildCheck: (id: string) => void;
  deleteChildCheck: (id: string) => void;
  setMessage: (message: string) => void;
  setOpen: (open: boolean) => void;
}

const TransactionItem: FC<TransactionItemProps> = ({
  title,
  date,
  color,
  shortCode,
  idUser,
  idCoin,
  validatorCount,
  validatorRequired,
  refetch,
  handleChildCheck,
  deleteChildCheck,
  setMessage,
  setOpen,
}) => {
  const classes = useStyles();
  const [check, setCheck] = useState<boolean>(false);
  const [putIntoCirculationMutation ] = useMutation<
    putIntoCirculation,
    putIntoCirculationVariables
  >(PUT_INTO_CIRCULATION, {
    onCompleted: () => {
      setMessage('Mise en service effectuée avec succès');
      setOpen(true);
    },
    onError: (errors) => {
      setMessage('un problème a été rencontré');
      setOpen(true);
    },
  });

  const timestamp = date !== null ? Number(date) : NaN;
  const date1 = new Date(timestamp);
  const formattedDate = date1.toLocaleDateString();
  const formattedTime = date1.toLocaleTimeString();
  const handleCirculation = async () => {
    try {
      const response = await putIntoCirculationMutation({
        variables: {
          putIntoCirculationInput: {
            coinIds: [idCoin],
          },
        },
      });
      refetch();
      console.log('Mutation réussie : ', response.data);
    } catch (err) {
      console.error('Erreur lors de l’exécution de la mutation : ', err);
    }
  };
  const handClick = () => {
    setCheck(!check);

    if (!check && idCoin !== null) {
      handleChildCheck(idCoin);
    } else if (check && idCoin !== null) {
      deleteChildCheck(idCoin);
    }
  };
  return (
    <Box className={classes.root}>
      <Box className={classes.case}>
        <Box className={classes.object}>
          <Checkbox
            className={classes.check}
            disabled={validatorCount < validatorRequired ? true : false}
            checked={check}
            onChange={handClick}
          />
          <Pictogram color={color || '#4B7FD5'} shortCode={shortCode || ''} />
        </Box>
        <Box className={classes.infoContainer}>
          <Box className={classes.cardInfo}>
            <Box className={classes.contentInfo}>
              <Box className={classes.info}>
                <Typography className={classes.title}>{title}</Typography>
              </Box>
              <Box className={classes.info}>
                <AccountCircleIcon className={classes.calendarIcon} />
                <Box className={classes.icon}>{idUser}</Box>
              </Box>
              <Box className={classes.info}>
                <MdCalendarViewMonth className={classes.calendarIcon} />
                <Typography className={classes.date}>
                  {` ${formattedDate} ${formattedTime}`}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.cardInfo}>
            <Typography variant="h6">{validatorCount}</Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.bar}></Box>
      <Box className={classes.case}>
        <Button
          variant="contained"
          className={classes.button}
          disabled={validatorCount < validatorRequired ? true : false}
          onClick={handleCirculation}
        >
          REVERSEMENT
        </Button>
      </Box>
    </Box>
  );
};

export default TransactionItem;
