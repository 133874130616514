import { gql } from "@apollo/client";
import { ARCHIVE_FRAGMENTS_INFO } from "./fragment";

export const GET_ARCHIVE = gql`
  query Archive($args: GetArchiveArgs) {
    affinityCurrenciesArchives(getArchiveArgs: $args) {
      ...archiveInfo
    }
  }
  ${ARCHIVE_FRAGMENTS_INFO}
`;
