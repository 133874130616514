import gql from "graphql-tag";

export const DO_SIGNIN = gql`
  mutation login($userLoginInput: loginInput) {
    login(userLoginInput: $userLoginInput) {
      accessToken
      userPartialInfos {
        userName
        isAdmin
      }
    }
  }
`;
