import { createStyles, makeStyles, Theme } from '@material-ui/core';
import background from '../../../assets/img/background.svg';

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      fontFamily: 'Poppins, sans-serif',
      width: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage: `url(${background})`,
      backgroundAttachment: 'fixed',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      margin: 0,
      padding: 0,
      position: 'relative',
      '@media (max-height: 604px)': {
        height: '100vh',
      },
      '@media (max-width: 991px)': {
        backgroundImage: 'initial',
        height: '100vh',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#4B7FD5',
            borderWidth: 1,
          },
        },
      },
      '& .MuiFormHelperText-root.Mui-error': {
        marginTop: '-1px',
        fontSize: '12px',
      },
    },
    cadre: {
      width: 'auto',
      maxWidth: 450,
      height: 'auto',
      maxHeight: 750,
      background: '#FFFFFF',
      border: '1px solid #E9EAEC',
      borderRadius: 6,
      opacity: 1,
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: 30,
      boxSizing: 'border-box',
      '@media (max-width: 639px)': {
        padding: 20,
        width: 'auto',
        maxWidth: 300,
      },
    },
    textField: {
      width: 369,
      background: '#FFFFFF',
      opacity: 1,
      height: 42,
      margin: '0 0 20px',
      '@media (max-width: 639px)': {
        width: '100%',
      },
      '&:last-of-type': {
        marginBottom: 30,
      },
      '& label': {
        color: '#A8AAB3',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
      },
      '& .MuiOutlinedInput-root': {
        '&:hover': {
          borderColor: '#B48A4E',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#B48A4E',
          },
        },
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 14px) scale(1)',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -5px) scale(0.75)',
        },
      },
      '& .MuiInputLabel-formControl': {
        fontFamily: 'Poppins, sans-serif',
      },
      '& input': {
        borderRadius: 6,
        padding: '12px 14px',
        fontSize: '1.1em',
        '&:focus': {
          outline: 'none',
        },
        '&:cursor': {
          borderColor: '#B48A4E',
        },
      },
    },
    logintext: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '1em',
      textAlign: 'center',
      lineHeight: '24px',
      letterSpacing: '.25px',
      color: '#212226',
      justifyContent: 'center',
      margin: '0 0 30px',
      '@media (max-width: 639px)': {
        width: '100%',
      },
    },
    textselect: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '1em',
      textAlign: 'center',
      lineHeight: '24px',
      letterSpacing: '.25px',
      color: '#212226',
      justifyContent: 'center',
      margin: '0px 21px 0px 0px',
      '@media (max-width: 639px)': {
        width: '100%',
      },
    },
    btnconnect: {
      boxShadow: 'none',
      fontSize: '16px',
      width: 369,
      height: 42,
      borderRadius: 4,
      margin: 0,
      '@media (max-width: 639px)': {
        width: '100%',
      },
    },
    btnchoicactive: {
      boxShadow: 'none',
      fontSize: '16px',
      width: 100,
      height: 42,
      borderRadius: 20,
      margin: 0,
      '@media (max-width: 639px)': {
        width: '100%',
      },
    },
    btnchoicedisable: {
      boxShadow: 'none',
      fontSize: '16px',
      width: 100,
      height: 42,
      borderRadius: 20,
      margin: 0,
      '@media (max-width: 639px)': {
        width: '100%',
      },
    },

    logo: {
      height: 100,
      marginBottom: 6,
      width: 160,
    },
    textTypography: {
      fontFamily: 'Poppins, sans-serif',
      width: 50,
      height: 50,
      backgroundColor: ' #4B7FD5',
      borderRadius: 10,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      transform: 'rotate(45deg)',
      color: '#fff',
      margin: 8,
      '& > span': {
        lineHeight: '100%',
        transform: 'rotate(-45deg)',
        '& strong': {
          fontWeight: 700,
        },
      },
    },
    textBienvenue: {
      color: '#212226',
      fontFamily: 'Poppins, sans-serif',
      fontSize: '1.5em',
      fontWeight: 400,
      lineHeight: '28px',
      letterSpacing: 0.18,
      marginBottom: 16,
      textAlign: 'center',
    },
    boxContentInfo: {
      display: 'flex',
      alignItems: 'baseline',
      flexDirection: 'column',
      marginBottom: '24px',
    },
    boxContentTitle: {
      font: 'normal normal normal 14px/16px Poppins',
      color: '#212226',
      letterSpacing: 0.4,
      marginBottom: '8px',
      fontWeight: 'bolder',
    },
    boxContentText: {
      font: 'normal normal normal 16px/18px Poppins',
      color: '#212226',
      letterSpacing: 0.46,
    },
    boxContentItem: {
      width: '100%',
      wordBreak: 'break-all',
      margin: '8px 0px 12px 0px',
    },
    link: {
      paddingTop: '16px',
      color: '#B48A4E',
      fontSize: '1em',
    },
    persontext: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '1em',
      textAlign: 'right',
      lineHeight: '24px',
      letterSpacing: '.25px',
      color: '#212226',
      margin: '-5px 175px 12px 4px',
      width: 'fit-content',
      '@media (max-width: 639px)': {
        width: '100%',
        textAlign: 'right',
      },
    },
    btnContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      margin: '0 0 15px 0',
    },
    toggleContainer: {
      position: 'relative',
      display: 'flex',
      width: '369px',
      borderRadius: 30,
      background: '#eeebebff',
      fontWeight: 'bold',
      color: '#343434',
      cursor: 'pointer',
      padding: 0,
      overflow: 'hidden',
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '50%',
        height: '100%',
        left: '0%',
        borderRadius: 30,
        background: '#B48A4E',
        transition: 'all 0.3s',
      },
    },
    toggleCheckbox: {
      display: 'none',
      '&:checked + $toggleContainer::before': {
        left: '50%',
      },
      '&:checked + $toggleContainer div:first-child': {
        color: '#B48A4E',

        transition: 'color 0.3s',
      },
      '&:checked + $toggleContainer div:last-child': {
        color: 'white',
        transition: 'color 0.3s',
      },
      '& + $toggleContainer div:first-child': {
        color: 'white',

        transition: 'color 0.3s',
      },
      '& + $toggleContainer div:last-child': {
        color: '##B48A4E',
        transition: 'color 0.3s',
      },
    },
    toggleContainerDiv: {
      flex: 1,
      padding: 14,
      textAlign: 'center',
      zIndex: 1,
      color: '#B48A4E',
    },
    formControle: {
      padding: 10,
      '& .MuiOutlinedInput-input': {
        padding: '13px 16px', // Modification du padding
      },
    },
    cardAccess: {
      display: 'flex',
      flexDirection: 'column',
      width: '94%',
    },
    item: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: '10px',
      position: 'relative',
    },
    label: {
      flex: 1,
      textAlign: 'left',
      marginBottom: '30px',
      fontWeight: 700,
    },
    value: {
      position: 'absolute',
      left: '15px',
      bottom: '0',
      textAlign: 'left',
      marginTop: _theme.spacing(2),
    },
    inputDropDown: {
      padding: '12.5px 14px',
    },
    cardPP: {
      padding: '10px',
    },
  }),
);
