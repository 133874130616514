import React, { useState } from 'react';
import { CardContent, Typography } from '@material-ui/core';
import { useStyles } from '../style';
import CardSetting from '../../../../common/CardSetting/CardSetting';
import { useMutation, useQuery } from '@apollo/client';
import {
  setting,
  setting_setting,
} from '../../../../graphql/Setting/__generated__/setting';
import { GET_SETTING } from '../../../../graphql/Setting/query';
import {
  updateSetting,
  updateSettingVariables,
} from '../../../../graphql/UpdateSetting/__generated__/updateSetting';
import { UPDATE_SETTING } from '../../../../graphql/UpdateSetting/mutation';
import { CustomDialog } from '../../../../common/customDialog/CustomDialog';

const settingInit: setting_setting = {
  __typename: 'Setting',
  id: '',
  amountOfCreationCostLegalEntity: 0,
  amountOfCreationCostNaturalPerson: 0,
  amountOfValidationCost: 0,
  numberOfValidatorsLegalEntity: 0,
  numberOfValidatorsNaturalPerson: 0,
  payoutAmount: 0,
};

interface SettingProps {
  setReload: (value: boolean | ((prevState: boolean) => boolean)) => void
}

const Setting: React.FC<SettingProps> = ({ setReload }) => {
  const [lastSetting, setLastSetting] = useState<setting_setting>({
    ...settingInit,
  });
  const [newSetting, setNewSetting] = useState<setting_setting>({
    ...settingInit,
  });
  const [open, setOpen] = useState<boolean>(false);
  const [fieldToUpdate, setFieldToUpdate] = useState<
    keyof setting_setting | null
  >(null);
  const style = useStyles();

  const { data: dataSetting, loading: loadSetting } = useQuery<setting>(
    GET_SETTING,
    {
      onCompleted: (data) => {
        if (data?.setting) {
          const { setting } = data;
          setNewSetting({ ...setting });
          setLastSetting({ ...setting });
        }
      },
    },
  );

  const [updateSetting, { loading: loadingUpdateSetting }] = useMutation<
    updateSetting,
    updateSettingVariables
  >(UPDATE_SETTING);

  const handleFieldChange = (field: keyof setting_setting, value: number) => {
    setNewSetting((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleOnBlur = (name: keyof setting_setting) => {
    if (lastSetting[name] !== newSetting[name]) {
      setFieldToUpdate(name); // Stocke le champ à mettre à jour
      setOpen(true); // Ouvre la modale après le blur si la valeur a changé
    }
  };

  const handleClickConfirm = async () => {
    if (fieldToUpdate) {
      try {
        const { data } = await updateSetting({
          variables: {
            updateSettingInput: {
              id: newSetting.id || '',
              [fieldToUpdate]: newSetting[fieldToUpdate],
            },
          },
        });
        if (data?.updateSetting) {
          setLastSetting((prev) => ({
            ...prev,
            [fieldToUpdate]: newSetting[fieldToUpdate],
          }));
          setReload(s => !s);
        }
      } catch (err) {
        console.error('Error updating setting:', err);
      }
    }
    setOpen(false); // Fermer la modale après confirmation
  };

  return (
    <>
      <CustomDialog
        open={open}
        setOpen={setOpen}
        singleActionButton={true}
        maxWidth="sm"
        onClick={handleClickConfirm}
        className={style.confirmDialog}
      >
        <Typography variant="h4" className={style.subTitre}>
          Confirmez-vous le changement ?
        </Typography>
      </CustomDialog>

      <CardContent className={style.cardContent}>
        <CardSetting
          title="Montant du coût création / personne morale:"
          text="Coût CPM"
          priceT={newSetting.amountOfCreationCostLegalEntity}
          name="amountOfCreationCostLegalEntity"
          loading={loadingUpdateSetting}
          onChange={handleFieldChange}
          handleOnBlur={handleOnBlur}
        />
      </CardContent>
      <CardContent className={style.cardContent}>
        <CardSetting
          title="Montant du coût création / personne physique:"
          text="Coût CPP"
          priceT={newSetting.amountOfCreationCostNaturalPerson}
          name="amountOfCreationCostNaturalPerson"
          loading={loadingUpdateSetting}
          onChange={handleFieldChange}
          handleOnBlur={handleOnBlur}
        />
      </CardContent>
      <CardContent className={style.cardContent}>
        <CardSetting
          title="Montant du coût validation / personne physique:"
          text="Coût VPP"
          priceT={newSetting.amountOfValidationCost}
          name="amountOfValidationCost"
          loading={loadingUpdateSetting}
          onChange={handleFieldChange}
          handleOnBlur={handleOnBlur}
        />
      </CardContent>
      <CardContent className={style.cardContent}>
        <CardSetting
          title="Montant du reversement:"
          text="Reversement PM-PP"
          priceT={newSetting.payoutAmount}
          name="payoutAmount"
          loading={loadingUpdateSetting}
          onChange={handleFieldChange}
          handleOnBlur={handleOnBlur}
        />
      </CardContent>
      <CardContent className={style.cardContent}>
        <CardSetting
          title="Nombre de validateurs pour une personne morale:"
          text="Nombre PM"
          priceT={newSetting.numberOfValidatorsLegalEntity}
          name="numberOfValidatorsLegalEntity"
          loading={loadingUpdateSetting}
          onChange={handleFieldChange}
          handleOnBlur={handleOnBlur}
        />
      </CardContent>
      <CardContent className={style.cardContent}>
        <CardSetting
          title="Nombre de validateurs pour une personne physique:"
          text="Nombre PP"
          priceT={newSetting.numberOfValidatorsNaturalPerson}
          name="numberOfValidatorsNaturalPerson"
          loading={loadingUpdateSetting}
          onChange={handleFieldChange}
          handleOnBlur={handleOnBlur}
        />
      </CardContent>
    </>
  );
};

export default Setting;
