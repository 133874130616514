import { TOKEN } from "../constant";

const USER = "user";

export const setUser = (user: any) => {
  const token = localStorage.getItem(TOKEN);
  if (!!token) {
    try {
      localStorage.setItem(USER, JSON.stringify({ ...user }));
    } catch (error) {
      console.log("error", error);
    }
  }
};

export const getUser = () => {
  const token = localStorage.getItem(TOKEN);
  if (token) {
    try {
      const item = localStorage.getItem(USER);
      if (item) {
        const user = JSON.parse(item);
        return user;
      }
    } catch (error) {}
  }

  return null;
};
