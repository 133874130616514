import gql from "graphql-tag";
import { TRANSACTION_HISTORY_FRAGMENT_INFOS } from "../TransactionHistory/fragment";

export const OnHistoryUpdate = gql`
  subscription onHistoryUpdate($address: String) {
    onHistoryUpdate(address: $address) {
      ...transactionHistoryFragmentInfos
    }
  }
  ${TRANSACTION_HISTORY_FRAGMENT_INFOS}
`;
