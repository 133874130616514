import gql from "graphql-tag";

export const GET_TOTAL = gql`
  query SumAvailableBalance {
    sumAvailableBalance {
        users
        euCoins
        affinity
    }
  }
`;
