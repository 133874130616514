import { Box, Typography } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import user from '../../../assets/icon/user.svg';
import { AuthContext } from '../../../provider/Authprovider';
import { useStyles } from './Balance/style';
import { useApolloClient, useQuery, useSubscription } from '@apollo/client';
import {
  capitalizeFirstLetter,
  formatUsernameWithCountryCode,
} from '../../../utils/helpers';
import { GET_TOTAL } from '../../../graphql/Total/query';
import { SumAvailableBalance, SumAvailableBalance_sumAvailableBalance } from '../../../graphql/Total/__generated__/SumAvailableBalance';
import { OnTotalUpdated } from '../../../graphql/Total/subscription';
import { onTotalUpdate } from '../../../graphql/Total/__generated__/onTotalUpdate';

interface HeaderProps {
  titleheader: string;
  subtitle: string;
  texte: string;
  withUserId?: boolean;
}

const Header: FC<HeaderProps> = ({
  titleheader,
  texte,
  subtitle,
  withUserId,
}) => {
  const style = useStyles();
  const client = useApolloClient();
  const { logout, userInfos } = useContext(AuthContext);
  if (
    (userInfos && !userInfos.userName) ||
    localStorage.getItem('isFirstTab') === 'true'
  ) {
    logout();
  }

  const { data: totalData } = useQuery<SumAvailableBalance, SumAvailableBalance_sumAvailableBalance>(GET_TOTAL);

  useSubscription<onTotalUpdate>(OnTotalUpdated, {
    onSubscriptionData: (data) => {
      if (
        data &&
        data.subscriptionData &&
        data.subscriptionData.data &&
        data.subscriptionData.data.onTotalUpdate
      ) {
        const cachedData = client.readQuery<SumAvailableBalance>({
          query: GET_TOTAL,
        });

        client.writeQuery<SumAvailableBalance>({
          query: GET_TOTAL,
          data: {
            ...cachedData,
            sumAvailableBalance:
              data.subscriptionData.data.onTotalUpdate,
          },
        });
      }
    },
  });

  const normalizeValue = (value: number) => {
    return value.toFixed(2).replace(".", ",");
  };
  
  return (
    <Box className={style.wrapHeader}>
      <Box className={style.boxStatus}>
        <Typography variant="h6">TOTAL :</Typography>
        <Box className="wraplist">
          <Box className="list">
            <Typography className={style.separatorText}>
              Users =
            </Typography>
            <Typography className={style.separator}>
              {normalizeValue(totalData?.sumAvailableBalance?.users || 0)}
            </Typography>
            <Typography className={style.separator}>-</Typography>
          </Box>
          <Box className="list">
            <Typography className={style.separatorText}>
              K EU-coins =
            </Typography>
            <Typography className={style.separator}>
              {normalizeValue(totalData?.sumAvailableBalance?.euCoins || 0)}
            </Typography>
            <Typography className={style.separator}>-</Typography>
          </Box>
          <Box className="list">
            <Typography className={style.separatorText}>
              K EU-xxx =
            </Typography>
            <Typography className={style.separator}>
              {normalizeValue(totalData?.sumAvailableBalance?.affinity || 0)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={style.boxheader}>
        <Box className={style.boxTitle}>
          <Typography variant={'h4'} className={style.textAccueil}>
            {titleheader}
          </Typography>
        </Box>
        <Box {...{ display: 'flex' }} className="userProfil">
          <Box className="user_name">
            <Typography variant={'h5'}>Bonjour,</Typography>
            <Typography variant={'h4'}>
              {userInfos && userInfos.isAdmin
                ? userInfos.userName?.toUpperCase()
                : formatUsernameWithCountryCode(
                    capitalizeFirstLetter(
                      userInfos?.userName || ''
                    ),
                  )
              }
            </Typography>
          </Box>

          <Box className="user_avatar">
            <img {...{ src: user, alt: 'user icon', className: 'user-icon' }} />
          </Box>
        </Box>
      </Box>
      <Box className={style.boxintro}>
        <Typography variant="h2">{subtitle}</Typography>
        <Typography variant={'h3'} color="textSecondary">
          {texte}
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;
