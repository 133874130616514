import gql from 'graphql-tag';

export const PUT_INTO_CIRCULATION = gql`
  mutation putIntoCirculation(
    $putIntoCirculationInput: putIntoCirculationInput
  ) {
    putIntoCirculation(putIntoCirculationInput: $putIntoCirculationInput) {
      coins {
        id
        isValidated
      }
    }
  }
`;
