import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  cardRoot: {
    boxShadow: "none",
    borderRadius: 6,
    padding: "12px 16px 13px",
    width: "20%",
    //width: 298,
    "@media (max-width: 1365px)": {
      width: "25%",
    },
    "@media (max-width: 1199px)": {
      width: "25%",
    },
    "@media (max-width: 1023px)": {
      width: "33.33333%",
    },
    "@media (max-width: 767px)": {
      width: "50%",
    },
    "@media (max-width: 575px)": {
      padding: "6px 8px",
      width: "100%",
    },
    "@media (min-width: 1920px)": {
      width: 300,
    },
  },
  cardcontent: {
    border: "1px solid #E9EAEC",
    borderRadius: 6,
    padding: 20,
    "@media (max-width: 1279px)": {
      padding: 15,
    },
    "@media (max-width: 575px)": {
      padding: 10,
    },
    "&:last-child": {
      paddingBottom: 20,
      "@media (max-width: 1279px)": {
        padding: 15,
      },
      "@media (max-width: 575px)": {
        padding: 10,
      },
    },
  },
  logocontaint: {
    borderRadius: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50,
  },
  details: {
    marginLeft: 10,
    "& h5": {
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: 12,
      lineHeight: "24px",
      margin: "0 0 2px",
    },
    "& h6": {
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: 22,
      fontWeight: 700,
      lineHeight: "24px",
      margin: 0,
    },
    "& .MuiTypography-body1": {
      whiteSpace: "nowrap",
    },
  },
  arrowIcon: {
    fontSize: 10,
    margin: 0,
  },
  boxText: {
    marginTop: 12,
  },
  pos: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 14,
    lineHeight: "16px",
    height: 24,
    marginBottom: 12,
  },
  dateText: {
    width: 138,
    color: "#686B78",
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    lineHeight: "16px",
    margin: 0,
    "& .MuiSvgIcon-root": {
      fill: "#686B78",
      marginRight: 6,
    },
  },

  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },

  boxcard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  boxTop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  imglogo: {
    width: 60,
    height: 60,
    border: "1px solid #8170DA",
    borderRadius: 16,
  },
  date: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});
