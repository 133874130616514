import { Box, Divider } from "@material-ui/core";
import React from "react";
import BodyTransfert from "./BodyTransfert";
import HeaderTransfert from "./HeaderTransfert";
import { useStyles } from "./style";

export const Transfert = () => {
  const classes = useStyles();
  return (
    <Box>
      <HeaderTransfert />
      <Divider />
      <Box className={classes.bodyTransfert}>
        <BodyTransfert />
      </Box>
    </Box>
  );
};
