import gql from "graphql-tag";

export const DO_GET_BALANCE = gql`
  query GET_BALANCE {
    balances {
      currency
      value
      code
      shortCode
      color
    }
  }
`;

export const DO_GET_CURRENCY_BALANCE = gql`
  query GET_CURRENCY_BALANCE($currencyId: Int!) {
    currencyBalace(currencyId: $currencyId) {
      value
      currency
      code
      shortCode
      color
    }
  }
`;

export const GET_TOTAL = gql`
  query TOTAL {
    ChainAvailableAmount {
      userName
      data {
        currency
        value
        code
        shortCode
        color
      }
    }
  }
`;
